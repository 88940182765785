<template>
	<div class="statement">
		<div class="list">
			<div class="item" v-for="(item, index) in $t('statement.content')" :key="index">
				{{item}}
			</div>
		</div>
	</div>
</template>

<script>
	import list from './statementData';
	export default {
		data() {
			return {
				list: []
			}
		},
		created() {
			this.list = list;
		}
	}

</script>

<style lang="scss">
	.statement {
		.list {
			padding: 16px 18px 35px;
			text-align: left;
			width: 800px;
			margin: 0 auto;
			color:rgba(66,69,98,1);
			line-height:22px;
			.item {
				margin-bottom: 5px;
			}
		}
	}
</style>
