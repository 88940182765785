<template>
    <div class="details">
        <div class="warp">
            <img src="/img/details.jpg">        

        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.details{
    img{
        margin:100px auto;
    }
}

</style>