var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"warp"},[_c('h1',[_vm._v(_vm._s(_vm.$t('index.trust')))]),_c('p',[_vm._v(_vm._s(_vm.$t('index.advantage')))])])]),_c('div',{staticClass:"choose ban"},[_c('div',{staticClass:"warp"},[_c('div',{staticClass:"titlee"},[_vm._v(_vm._s(_vm.$t('index.choose'))+" "),_c('p',[_vm._v("WHY TRADEST GCT Co., Limited")])]),_c('div',{staticClass:"paragraph"},[_vm._v(" "+_vm._s(_vm.$t('index.dount'))+" ")]),_c('div',{staticClass:"square"},[_c('div',{staticClass:"content",on:{"mouseenter":function($event){return _vm.blur(1)},"mouseleave":_vm.blurOut}},[_vm._m(0),( _vm.index!==1 )?_c('p',[_vm._v(_vm._s(_vm.$t('index.safty')))]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.index === 1),expression:"index === 1"}],staticClass:"layer",style:({
                        width: _vm.width - 30 + 'px',
                        height: _vm.height + 'px'})},[_c('p',[_vm._v(_vm._s(_vm.$t('index.safty')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('index.connectionContent'))+" ")])])])],1),_c('div',{staticClass:"content",on:{"mouseenter":function($event){return _vm.blur(2)},"mouseleave":_vm.blurOut}},[_vm._m(1),( _vm.index!==2 )?_c('p',[_vm._v(_vm._s(_vm.$t('index.connection')))]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.index === 2)?_c('div',{staticClass:"layer",style:({
                      width: _vm.width - 30 + 'px',
                      height: _vm.height + 'px'})},[_c('p',[_vm._v(_vm._s(_vm.$t('index.connection')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('index.customerContent'))+" ")])]):_vm._e()])],1),_c('div',{staticClass:"content",on:{"mouseenter":function($event){return _vm.blur(3)},"mouseleave":_vm.blurOut}},[_vm._m(2),( _vm.index!==3 )?_c('p',[_vm._v(_vm._s(_vm.$t('index.customer')))]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.index === 3)?_c('div',{staticClass:"layer",style:({
                      width: _vm.width - 30 + 'px',
                      height: _vm.height + 'px'})},[_c('p',[_vm._v(_vm._s(_vm.$t('index.customer')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('index.excellentContent'))+" ")])]):_vm._e()])],1),_c('div',{staticClass:"content",on:{"mouseenter":function($event){return _vm.blur(4)},"mouseleave":_vm.blurOut}},[_vm._m(3),( _vm.index!==4 )?_c('p',[_vm._v(_vm._s(_vm.$t('index.excellent')))]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.index === 4)?_c('div',{staticClass:"layer",style:({
                        width: _vm.width - 30 + 'px',
                        height: _vm.height + 'px'})},[_c('p',[_vm._v(_vm._s(_vm.$t('index.excellent')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('index.customerContent'))+" ")])]):_vm._e()])],1)])])]),_c('div',{staticClass:"partner ban"},[_c('div',{staticClass:"warp"},[_c('div',{staticClass:"titlee"},[_vm._v(_vm._s(_vm.$t('index.partner'))+" "),_c('p',[_vm._v("COOPERATIVE PARTNER")])]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"xs":12,"md":{span:4,offset:2}}},[_c('img',{attrs:{"src":"/img/logo1.png"}})]),_c('el-col',{attrs:{"xs":12,"md":4}},[_c('img',{attrs:{"src":"/img/logo2.png"}})]),_c('el-col',{attrs:{"xs":12,"md":4}},[_c('img',{attrs:{"src":"/img/logo3.png"}})]),_c('el-col',{attrs:{"xs":12,"md":4}},[_c('img',{attrs:{"src":"/img/logo4.png"}})]),_c('el-col',{attrs:{"xs":{span:12,offset:6},"md":4}},[_c('img',{attrs:{"src":"/img/logo5.png"}})])],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":"/img/trade1.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":"/img/trade2.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":"/img/trade3.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":"/img/trade4.png"}})])}]

export { render, staticRenderFns }