const locale = (language, key = 'language') => {
  const defaultLanguage = /cn/i.test(window.navigator.language) ? 'cn' : 'en';
  if (language) {
    localStorage.setItem(key, language);
  } else if (language === null) {
    localStorage.remove(key);
  } else {
    language = localStorage.getItem(key);
  }
  return language || defaultLanguage;
}
const autoImgSize = ( imgwidth, imgheight, rewidth, reheight ) => {
    let hRatio;
    let wRatio;
    let Ratio = 1;
    const maxWidth = rewidth;
    const maxHeight = reheight;
    const w = imgwidth;
    const h = imgheight;
    wRatio = maxWidth / w;
    hRatio = maxHeight / h;
    Ratio = ( wRatio <= hRatio ? wRatio : hRatio );
    return {
        width: Math.floor( w * Ratio ),
        height: Math.floor( h * Ratio ),
    };
};
const open = (type) => {
  const keys = {
    login: window.LOGIN_PATH,
    regist: window.REGISTER_PATH,
  };
  window.open(keys[type]);
}
const download = (key) => {
  const type = {
    ios: window.IOS_DOWNLOAD_URL,
    android: window.ANDROID_DOWNLOAD_URL,
    pc: window.PC_DOWNLOAD_URL,
  };
  window.open(type[key]);
}
export default {
  locale,
  autoImgSize,
  open,
  download
}