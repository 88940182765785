module.exports = {
  header: {
    languageSetting: 'Language Setting',
    pc: {
      home: 'Home',
      introduction:'Introduction',
      choose:'Why GCT',
      tools:'Trading Tool - ',
      contact:'Contact Us',
      register:'Sign Up',
      login:'Log In',
      chinese:'English',
      tradational:'Chinese',
      english:'English',
      aboutUs: 'About Us',
      authority: 'Regulatory Authority',
      tradding: 'Trading Product - Financial software',
      and:'Product and Trading',
    },
    login:{
      userName:'User Name',
      enterUserName:'Please enter the user name',
      password:'Password',
      enterPassword:'Please enter the password',
      noAccound:'No GCT Co., Limited account？',
      signUp:'Sign Up',
      login:'Log In',
    },
  },
  //footer
  footer:{
    btx:'Introduction',
    xuanze:'Why GCT Co., Limited',
    and:'Product and Trading',
    us:'Contact Us',
    email:'Customer Email:',
    reply:'（Efficient reply within 24 hours)',
    skype:'Tel : +852 69583342',
    park:'Address : Room 1405， 14/F ，Lucky Centre， 165 Wanchai ROAD， Wan Chai ，Hong Kong',
    risk:'*Risk warning: GCT Co., Limited (hereinafter referred to as: GCT) reminds you that the high risks of margin trading and trading may incur losses that exceed your deposit. You do not actually own or hold any relevant underlying assets. Please before trading Read our product disclosure statement carefully. Please fully understand the risks involved before trading and carefully manage the risk exposure.',
	risk2: 'Copyright © 2019-2020 GCT Co., Limited All Rights Reserved'
  },
  // 首页
  index:{
    trust:'We Fight for Your Trust',
    advantage:'Discover our advantages and go to the global financial software market with GCT Co., Limited',
    alive:'Create a live account',
    choose:'WHY TRADEST GCT Co., Limited',
    locale: '*Region of your current usdt service provider: China',
    dount:'GCT Co., Limited knows that traders have different doubts and concerns at different phases in the financial market. Therefore, we have integrated an experienced global professional team at the beginning of our establishment, and have affirmed that the company principle is to build customers’ confidence and lower customers’ risks.Adhering to our original intentions of complying with the security regulations and being trustworthy, we must continuously innovate and develop. GCT Co., Limited has committed to bringing customers a secure operating system, an integrated trading tool, abundant financial resources and professional global support, and providing the best trading experience available and being an internationally leading Financial software trader that’s standardized and normalized.',
    safty:'Capital Security',
    saftyContent: 'In order to ensure the industry’s security criterion of fully segregation, the customers’ funds are separated from our company’s funds and are kept at different bank accounts.',
    connection:'Linked to the World',
    connectionContent: 'Mainstream financial software varieties and highly competitive trading environment, GCT Co., Limited takes you to the global financial software market.',
    customer:'Exclusive Customer Service',
    customerContent: ' is global investors’ first choice in terms of online trading platform. GCT Co., Limited provides you with desktop and mobile versions.',
    excellent:'Excellent Trading Platform',
    excellentContent: 'With professional service and the principle of “Customer First”, GCT Co., Limited offers you 24-hour service from Monday to Friday.',
    currency:'Financial software',
    provide:'GCT Co., Limited offers multiple types of Financial software. Through  trading, it has advantages like low latency, competitive price and institutional execution efficiency, etc.',
    sell:'Sell out',
    buy:'Buy in',
    highest:'Highest',
    lowest:'Lowest',
    fast:'',
    exchange:'FAST EXCHANGE',
    accounding:'Automatically choose your local USDT trading service provider based on your location. Exchange currencies without leaving the country.Available countries and regions: the US, Canada, Australia, the UK, France, Russia, China, Hong Kong SAR, Japan.',
    platform:'Trading Platform',
    through:'Through , GCT Co., Limited offers customers :',
    crypt:'Financial software',
    leverage:'Flexible Trading Leverage',
    profissional:'Professional Technical Analysis; including over 40 technical indicators and analytic tools.',
    alone:'Independent order and trading account system; including 4 trading order models.',
    experience:'Convenient mobile trading experience with smartphones.',
    desk:' Desktop',
    popular:'As the most popular trading tool,  has worked tightly with GCT Co., Limited and has brought successful investors a set of convenient and full-featured trading solutions, which is the modern investors’ best choice. The desktop version is suitable for all your desktop computers.',
    downland:'Download Now',
    partner:'Cooperative Partner',
    join:'Join Us',
    register:'Sign up',
    trade:'Sign up for a trading account.',
    verification:'Authentication',
    file:'Authenticate your documents.',
    if:'',
    funds:'Fund',
    deposit:'Deposit into your',
    in:'trading account.',
    jiao:'Trade',
    start:'Start trading',
    cfd:'Financial software.',
  },
  //选择GCT Co., Limited
  choose:{
    choose:'Why GCT Co., Limited?',
    hold:'Certified, Secure, Stable',
    regulated:'Fully Regulated and Globally Trusted.When you choose GCT Co., Limited, you’re actually choosing to trade at a fully-regulated and industry-leading platform.',
    protection:'Security Assurance',
    bank:'GCT Co., Limited was registered as an MSB in the US and has cooperated with Ernst & Young International Accounting Firm. We take measures such as fund segregation, information encryption and fund monitoring to ensure the security of our customers’ funds.',
    jianguan:'Regulation Assurance',
    international:'International certification and regulation',
    data:'Data Security',
    per:'Every order is transparent, secure and searchable.',
    money:'Fund Security',
    isolation:'Fund segregation and security assurance.',
    information:'Information Security',
    profess:'Professional techniques to encrypt the information.',
    speed:'Super-Speed Server',
    optical:'GCT Co., Limited uses an fiber optical network system to link our global trading servers, which guarantees the lightning fast execution of each transaction, and improves the execution speed by 78%. It can link up to 27 different liquidity service providers with more than 10,000 quoted price updates per minute. ',
    over:'Over',
    stability:'Stability Rate',
    promote:'Improve',
    execution:'Execution Speed',
    up:'Up to',
    fluidity:'Liquidity Service Providers',
    minute:'Every Minute',
    update:'Quoted Price Updates',
  },
  //联系我们
  contact:{
    us:'Contact Us',
    email:'Email',
    address:'Address',
	tel: 'Tel'
  },
  //产品交易
  product:{
    trade:'Trading Product',
    what:'What is Financial software?',
    jiamim:'Financial software provides all the characteristics of trading cryptocurrencies without directly owning the cryptocurrency itself. Financial software is a mirror reflection of cryptocurrency fluctuations. Through margin trading, earn the spread between the bid and the ask prices. In addition, CFD traders have more convenient financing and short selling conditions.',
    investor:'At GCT Co., Limited, investors can trade multiple Financial software in USD, such as Bitcoin, Bitcion Cash, Ethereum, Litecoin, Ripple, etc.',
    reality:'Real Financial Factors',
    unstable:'Instability of the real financial world increases the needs for cryptocurrency.',
    attitude:'Government Attitudes',
    positive:'Positive government attitude and loosing government environment make the price of cryptocurrencies go up.',
    influence:'Factors affecting',
    factor:'Financial software',
    out:'External Environmental Influences',
    all:'A country’s overall economy condition and geopolitical situation will also influence the price of cryptocurrency.',
    industry:'Industry Conditions',
    trend:'The current condition of cryptocurrency industry have an impact on its price.',
    advantage:'Advantages of Financial software',
    two:'Two-way trading, both profit and loss can be profitable',
    hdging:'Hedging risk',
    low:'Low threshold, high yield',
    small:'Margin trading to small and big',
    super:'Ultra-low transaction costs',
    no:'No daily limit',
    hot:'Popular trading products',
    service:'GCT Co., Limited is always committed to providing customers with innovative trading services and launching a variety of mainstream Financial software trading varieties',
    sell:'Sell out',
    buy:'Buy in',
    highest:'Highest',
    lowest:'Lowest',
    click:'Click for Agreement Details',
  },
  //监管机构
  regulatory:{
    authority:'Regulatory Authority',
    usa:'Certified MSB',
    business:'MSB is the abbreviation of Money Services Business, which is regulated by and registered at FinCEN (a bureau of the United States Department of the Treasury). GCT Co., Limited was registered as an MSB in the US in February 21, 2017. An MSB can deal with businesses that transmit or convert currency (including digital currency/cryptocurrency), and is strictly regulated and audited by FinCEN.',
    money:'Customer Fund Security',
    account:'All customers’ funds are kept in independent bank accounts and completely separated from our company’s operating funds. In the event of default, the funds can be returned directly to the customer and will not be considered a recoverable asset for general creditors. All customers’ funds are deposited in accounts of the highest level banks which are regulated.',
    out:' Fast and Convenient Withdrawal/Deposit',
    know:'We’re fully aware of how much our customers value withdrawals and deposits. That’s why we’re committed to delivering you a secure, fast and high-quality deposit/withdrawal experience. Meanwhile, we make sure every channel is stable, fast and convenient, allowing investors to make arrangements as fast as they can.',
    fair:'Fair to All ',
    devoted:'GCT Co., Limited is committed to:',
    definition:'providing highly transparent, efficient and professional services; regularly review our work and make improvements in order to satisfy customers’ all kinds of needs. ',
    get:'making sure that all customers get the same treatment, handling every complaint case fast and fairly and comply with related regulations.',
    support:'making sure that the value of “Fair to All” gets supported by all staff of our company.',
    ask:'making sure that customer service reach standards or even over regulatory requirements.',
  },
  //交易工具
  tools:{
    trade:'Trading Tool',
    mt4:'GCT Co., Limited Trading Platform',
    operation:'Fast and convenient to use',
    update:'Update in real time',
    safe:'Security assured',
    globe:'GCT Co., Limited trading platform is the world\'s most popular financial trading software used by financial institutions and banks. In addition to real-time market charts and indicators and trading functions, it also includes 18 index drawing tools, 29 international popular technical indicators, custom indicators and comprehensive and robust trading system to help you achieve your trading goals.',
    good:'GCT Co., Limited is widely praised by investors.',
    feature:'A complete risk-control function.',
    set:'offers many types of order settings to fully manage position risks and 100% stop loss.',
    strong:'Strong chart-drawing analysis function',
    chart:'The platform provides investors with 18 types of chart-drawing tools, 29 technical indicators, multiple chart templates and supports unlimited opening of charts, helping investors to make more accurate predictions and judgments.',
    power:'Powerful execution, fast and efficient',
    executive:'The trading platform has strong execution power, 100% of pending orders are automatically executed, and is known worldwide for its fast and efficient execution. At present, more than 100 financial institutions are using the  trading platform.',
    lang:'Multi-language platform interface.',
    simplify:'The trading platform provides 31 kinds of languages, such as Simplified Chinese, Traditional Chinese, English etc., providing high-quality investment services for Chinese people worldwide.',
    have:'Multiple security assurances. ',
    client:'trading platform’s and server date are authenticated by international-recognized technology, providing comprehensive security for investors.',
    free:'Free Simulation Trading',
    like:'trading platform has provided free simulation trading platform and been very popular with investors.',
    unparalleled:'Incomparable Technology Tool',
    market:'Market Quoted Prices - Use it to view your trading contracts and display them according to your needs.',
    visit:'Access all your accounts, and through dragging and dropping your charts and indicators and expert consultants and scripts into your them.',
    check:'Terminal - View your account balance, open positions, orders and transaction history, etc. ',
    how:'How do I start?',
    mt4desk:' Dekstop',
    cooperation:'As the most popular trading tool,  has worked tightly with GCT Co., Limited and has brought successful investors a set of convenient and full-featured trading solutions, which is the modern investors’ best choice. The desktop version is suitable for all your desktop computers.',
    window:'(window) Download Now',
    mobile:' Mobile',
    close:' has the best mobile terminal trading experience.  has worked tightly with GCT Co., Limited and has brought a set of convenient and full-featured trading solutions, which is the best choice for modern traders.',
    ios:'IOS Download',
    android: 'Android Download',
  },
  //简介
  troduction:{
    btx:'GCT Co., Limited Introduction',
    brand:'GCT Co., Limited is the world\'s leading brand of online Financial software trading service providers. It has offices and service centers in the United States, the United Kingdom, Seychelles, Hong Kong China, and Malaysia to provide mainstream Financial software financial derivatives trading services to global investors.',
    pioneer:'As a global market leader, GCT Co., Limited is committed to connecting individual investors with the global financial market. It has been in the field of trading platform services for 5 years and provides a professional and transparent trading environment and excellent trading experience for global investors.',
    spread:'Since its establishment, GCT Co., Limited has provided trading services of Financial software and other popular products to more than 1 million investors from over 30 countries through the universal  platform worldwide. With the most competitive spreads, millisecond-measured order execution speed, and secure and stable money management services, GCT Co., Limited has won the trust of customers worldwide.',
    team:'Our Team',
    financial:'GCT Co., Limited has a professional team of financial analysts and customer service providers. The core members of the team have many years of strategic decision-making and team management experience in internationally renowned banks, top financial institutions, and Fortune 500 multinational corporations.',
    service:'Our Service',
    help:'In addition to an excellent trading platform, GCT Co., Limited also provides customers with 24-hour professional customer service to help you make trading decisions more conveniently and execute transactions with the most real-time information.',
    vision:'Our Vision',
    uphold:'Adhering to our original intentions of complying with the security regulations and being trustworthy, we must continuously innovate and develop. GCT Co., Limited has committed to bringing customers a secure operating system, a integrated trading tool, abundant financial resources and professional global support, and making the best trading experience available. ',

  },
	statement: {
		title: 'Privacy Policy',
		content: [
			`This application respects and protects the personal privacy of all users of the service. In order to provide you with more accurate and personalized services, this application will use and disclose your personal information in accordance with the provisions of the Privacy Policy. However, this application will treat this information with a high degree of diligence and prudence. Except as otherwise provided in the Privacy Policy, this application will not disclose this information or make it available to third parties without your prior permission. This application will update the Privacy Policy from time to time. `,
			`When you agree to the service usage agreement of this application, you are deemed to have agreed to the entire content of the Privacy Policy, which is an integral part of the service usage agreement.`,
			`1.  Scope of Application`,
			`(a) The personal registration information provided by you in accordance with the requirements of this application when you register the account of this application;`,
			`(b) Information automatically received and recorded by this application from your browser and computer when you use the web services of this application or visit the webpage of the application platform, including but not limited to your IP address, type of browser, language used, date and time of access, software and hardware features and webpage records you require;`,
			`(c) User personal data obtained by this application from business partners through legal channels.`,
			`You understand and agree that the Privacy Policy does not apply to the following information: `,
			`(a) the keyword information you enter when using the search service provided by the application platform; `,
			`(b) relevant information data collected by this application that you published in this application, which include but not limited to participation in activities, transaction information and evaluation details; `,
			`(c) Violations of laws or rules of this application and according actions taken against you by this application.`,
			`2. Use of Information`,
			`(a) This application will not provide, sell, rent, share or trade your personal information to any irrelevant third party, unless it has obtained your permission in advance, or the third party and this application (including affiliates of this application) provide services to you separately or jointly, and after the services end, they will be denied access to all such information, including that previously available to them.`,
			`(b) This application also does not allow any third party to collect, edit, sell or disseminate your personal information by any means. If any user of the application platform engages in the above activities, this application shall have the right to immediately terminate the service agreement with that user. `,
			`(c) For the purpose of serving users, this application may use your personal information to provide you with the information that you have interest in, including but not limited to sending you product and service information, or sharing information with the application partners so that they can send you information about their products and services (the latter requires your prior consent).`,
			`3. Information Disclosure `,
			`Under the following circumstances, this application will disclose your personal information in whole or in part at your personal will or as required by law:`,
			`(a) to disclose, with your prior consent, to a third party; `,
			`(b) to share your personal information with a third party in order to provide the products and services you request; `,
			`(c) to disclose to a third party or an administrative or judicial body in accordance with relevant laws or at the request of the administrative or judicial body;`,
			`(d) to disclose to a third party when you violate relevant laws and regulations of China or the service agreement or related rules of the application; `,
			`(e) to disclose to the respondent at his/her request if you are a qualified intellectual property complainant and have filed a complaint, so that the two parties can deal with the possible dispute over rights; `,
			`(f) in a transaction created on the application platform, if either party of the transaction fulfills or partially fulfills the transaction obligations and requests for information disclosure, this application has the right to decide to provide the user with the contact information of the counterparty and other necessary information, so as to facilitate the completion of the transaction or the resolution of the dispute.`,
			`(g) other disclosures that this application deems appropriate in accordance with laws, regulations or website policies.`,
			`4.  Information storage and exchange`,
			`The information and data about you collected by this application will be stored on the server of this application and/or its affiliates. Such information and data may be transferred to your country, region or outside the country where the information and data are collected by this application, and accessed, stored and displayed outside.`,
			`5. Use of Cookie`,
			`(a) If you do not reject cookies, this application will set or access cookies on your computer, so that you can log in or use the services or functions of this application platform that rely on cookies. This application uses cookies to provide you with more thoughtful personalized services, including promotional services. `,
			`(b) You have the right to choose to accept or reject cookies. You can reject cookies by modifying your browser settings. However, if you choose to reject, you may not be able to log in or use the web services or functions of this application that rely on cookies.`,
			`(c) This policy applies to the information obtained through cookies set by this application.`,
			`6.  Information Security`,
			`(a)Accounts of this application have security protection functions, please keep your user name and password information properly. This application will ensure that your information will not be lost, abused or altered through security measures such as encrypting user passwords. Despite the aforementioned security measures, please also note that there are no “perfect security measures” on the information network.`,
			` 7.Changes to the Privacy Policy`,
			`(a)If we decide to change our Privacy Policy, we will post these changes in this policy, on our website, and where we deem appropriate, so that you understand how we collect and use your personal information, who has access to it, and under which circumstances we disclose such information.`,
			`(b)The company reserves the right to modify this policy at any time, so please check it frequently. If major changes are made to this policy, the company will notify you through a notice on the website.`,
			`Please protect your personal information and provide it to others only when necessary. If you find that your personal information has been leaked, especially the user name and password of this application, please contact the customer service of this application immediately so that this application can take appropriat`,
		],
	}

}
