import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './style/base.css';
import './style/index.scss';
import './style/home.css';
import '@/common/navigate.js';
import '@/style/media.scss';
import '@/style/en.scss';
import Util from '@/common/util.js';

Object.defineProperty(Vue.prototype, '$util', {
  value: Util,
  writable: false,
  configurable: false,
  enumerable: false,
});

import i18n from  './i18n.js';

Vue.use(ElementUI);
Vue.config.productionTip = false

window.$root = new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
