<template>
  <div class="tools">
    <!-- banner -->
    <div class="backImg">
      <div class="bannerTit">{{$t('tools.trade')}}
        <!-- <p>TRADING TOOLS</p> -->
      </div>
    </div>

    <!-- MT4 -->
    <!-- <div class="mt4">
      <div class="warp">
        <div class="distance clearfix">
          <div class="leftText">
            <p class="headTitle">{{$t('tools.mt4')}}</p>
            <div class="lattleIcon clearfix hidden-xs">
              <div class="">
                <img src="/img/caozuo.png">
                <span>{{$t('tools.operation')}}</span>
              </div>
              <div>
                <img src="/img/caozuo2.png">
                <span>{{$t('tools.update')}}</span>
              </div>
              <div>
                <img src="/img/caozuo3.png">
                <span>{{$t('tools.safe')}}</span>
              </div>
            </div>
            <p class="littleText">{{$t('tools.globe')}}

            </p>
          </div>

          <div class="rightImg">
            <img src="/img/xiazai.png">
          </div>
          <div class="lattleIcon clearfix hidden-md">
            <div class="">
              <img src="/img/caozuo.png">
              <span>{{$t('tools.operation')}}</span>
            </div>
            <div>
              <img src="/img/caozuo2.png">
              <span>{{$t('tools.update')}}</span>
            </div>
            <div>
              <img src="/img/caozuo3.png">
              <span>{{$t('tools.safe')}}</span>
            </div>
          </div>

        </div>

      </div>

    </div> -->

    <!-- 好评 -->
    <div class="gComment">
      <div class="warp">
        <div class="distance">
          <p class="blackTitle">
            {{$t('tools.good')}}
          </p>
          <el-row>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping1.png">
                <p class="middleShort">{{$t('tools.feature')}}</p>
                <p>{{$t('tools.set')}}
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping2.png">
                <p class="middleShort">{{$t('tools.strong')}}</p>
                <p>{{$t('tools.chart')}}</p>
              </div>
            </el-col>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping3.png">
                <p class="middleShort">{{$t('tools.power')}}</p>
                <p>{{$t('tools.executive')}}
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping4.png">
                <p class="middleShort">{{$t('tools.lang')}}</p>
                <p>{{$t('tools.simplify')}}
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping5.png">
                <p class="middleShort">{{$t('tools.have')}}</p>
                <p>{{$t('tools.client')}}
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :md="8">
              <div class="borderBox">
                <img src="/img/haoping6.png">
                <p class="middleShort">{{$t('tools.free')}}</p>
                <p>{{$t('tools.like')}}
                </p>
              </div>
            </el-col>
          </el-row>

        </div>

      </div>

    </div>

    <!-- 工具箱 -->
    <div class="toolBox">
      <div class="warp">
        <div class="distance">
          <p class="blackTitle">{{$t('tools.unparalleled')}}</p>
          <el-row :gutter="100">
            <el-col :xs="24" :md="8">
              <img src="/img/gongjuxiang1.png">
              <p class="short">{{$t('tools.market')}}</p>
            </el-col>
            <el-col :xs="24" :md="8">
              <img src="/img/gongju2.png">
              <p class="short">{{$t('tools.visit')}}</p>
            </el-col>
            <el-col :xs="24" :md="8">
              <img src="/img/gongju3.png">
              <p class="short">{{$t('tools.check')}}</p>
            </el-col>
          </el-row>

        </div>

      </div>

    </div>

    <!-- 如何开始 -->
    <!-- <div class="start">
      <div class="warp">
        <div class="distance">
          <p class="blackTitle">{{$t('tools.how')}}</p>
          <div class="desk clearfix">
            <div class="leftText">
              <p class="headTitle">{{$t('tools.mt4desk')}}</p>
              <p class="littleText">
                {{$t('tools.cooperation')}}
              </p>
             
            </div>

            <div class="rightImg">
              <img src="/img/kaishi.png">
            </div>

          </div>
          <div class="desk clearfix">
            <div class="leftImg">
              <img src="/img/kaishi2.png">
            </div>
            <div class="rightText">
              <p class="headTitle">{{$t('tools.mobile')}}</p>
              <p class="littleText">
                {{$t('tools.close')}}
              </p>
              <img class="hiddenImgMd" src="/img/kaishi2.png">
             
            </div>

          </div>
        </div>

      </div>

    </div> -->

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.tools {
  .hidden-md {
    display: none;
  }
  .hidden-xs {
    display: block;
  }
  .hiddenImgMd {
    display: none;
  }

  .leftText {
    float: left;
    width: 45%;
    text-align: left;
    .headTitle {
      margin: 30px 0;
    }
    .lattleIcon {
      float: left;
      > div {
        float: left;
        margin-right: 30px;
        line-height: 50px;
        > img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
    span,
    .littleText {
      font-size: 16px;
      color: #273863;
      line-height: 24px;
      margin-top: 20px;
    }
  }
  .rightImg {
    float: right;
    width: 50%;
  }
  .downButton {
    color: #fff;
    padding: 15px 20px;
    font-size: 16px;
    font-family: "pingfangBold";
    background: #333;
    border-radius: 4px;
    margin-top: 40px;
    margin-right: 40px;
    > span {
      color: #fff;
    }
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .downButton:last-child {
    margin-right: 0;
  }
  .mt4 {
    background-image: url(/img/MT4.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .gComment {
    .borderBox {
      border: solid 1px #d8dde7;
      background-color: #fefefe;
      padding: 30px 30px 2px 30px;
      min-height: 230px;
      > img {
        margin-bottom: 10px;
        pointer-events: none;
      }
      p {
        font-size: 12px;
        color: #848eaf;
        line-height: 22px;
        pointer-events: none;
      }
      .middleShort {
        color: #141828;
        font-size: 14px;
        line-height: 40px;
      }
    }
    .el-col :hover {
      cursor: pointer;
      box-shadow: 2px 3px 27px 2px rgba(178, 178, 178, 0.31);
    }
  }

  .toolBox {
    img {
      margin: 30px;
    }
    .short {
      line-height: 26px;
      color: #141828;
    }
  }
  .start {
    background-image: url(/img/bg.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .desk {
      margin-top: 50px;
      font-size: 16px;
      line-height: 24px;
      color: #273863;
      .leftImg {
        float: left;
        width: 45%;
        > img {
          width: 100%;
        }
      }
      .rightText {
        float: right;
        width: 50%;
        text-align: right;
        margin-top: 20px;
      }
    }
  }
}
</style>