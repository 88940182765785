import Vue from 'vue'
import VueI18n from 'vue-i18n'
const en = require('@/language/en');
const cn = require('@/language/cn');
import Util from '@/common/util.js';

const messages = {en, cn};

Vue.use(VueI18n)
// $root.$util.locale
export default new VueI18n({
  locale: Util.locale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cn',
  messages,
});
