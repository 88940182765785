<template>
  <div>
    <transition name="login-fade-in">
      <div v-if="showPc" class="b-login-wrap">
        <div class="modal"></div>
        <div class="login-wrap">
          <div class="close" @click="show=false">
            <svg t="1575866549775" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="1106" width="24" height="24">
              <path
                d="M1022.793875 170.063604L852.730271 0 511.396938 341.333333 170.063604 0 0 170.063604l341.333333 341.333334L0 852.730271l170.063604 170.063604 341.333334-340.127208 341.333333 340.127208 170.063604-170.063604-340.127208-341.333333 340.127208-341.333334z"
                p-id="1107" fill="#333"></path>
            </svg>
          </div>
          <div class="form">
            <div class="form-item">
              <label for="userName">
                <span>
                  {{$t('header.login.userName')}}
                </span>
              </label>
              <div>
                <input :placeholder="$t('header.login.enterUserName')" type="text" id="userName" />
              </div>
            </div>
            <div class="form-item">
              <label for="password">
                <span>
                  {{$t('header.login.password')}}
                </span>
              </label>
              <div>
                <input :placeholder="$t('header.login.enterPassword')" type="password"
                  id="password" />
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="to-regist">
              {{$t('header.login.noAccound')}}<a href="javascript:void 0">
                {{$t('header.login.signUp')}}</a>
            </div>
            <div class="button">
              <span @click.stop="comfirm"> {{$t('header.login.login')}}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="login-mobile-fade-in">
      <div v-if="showMobile" class="b-login-wrap-mobile">
        <ul class="form">
          <li>
            <label for="userName">
              {{$t('header.login.userName')}}
            </label>
            <div>
              <input :placeholder="$t('header.login.enterUserName')" type="text" id="userName" />
            </div>
          </li>
          <li>
            <label for="password">
              {{$t('header.login.password')}}
            </label>
            <div>
              <input type="password" :placeholder="$t('header.login.enterPassword')"
                id="password" />
            </div>
          </li>
        </ul>
        <div class="to-regist">
          {{$t('header.login.noAccound')}}<a href="javascript:void 0">
            {{$t('header.login.signUp')}}</a>
        </div>
        <div class="buttons">
          <span>{{$t('header.login.login')}}</span>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  created() {
    this.$root.$on('showLogin', () => {
      window.open(window.LOGIN_PATH);
      // this.show = !this.show;
    });
  },
  computed: {
    showPc() {
      return document.documentElement.offsetWidth > 760 && this.show;
    },
    showMobile() {
      return document.documentElement.offsetWidth <= 760 && this.show;
    }
  }
}
</script>
<style lang="scss">
.b-login-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: 999;
  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // opacity: .3;
    // background: #fefefe;
    // background: #fff;
    // cursor: not-allowed;
    // filter: opacity(30%)
  }
  .login-wrap {
    box-shadow: 0px 0px 5px #333;
    position: absolute;
    width: 400px;
    height: 280px;
    background: #fefefe;
    padding: 30px;
    transform: translate(calc(50vw - 165px), calc(50vh - 230px));
    // margin-left: calc(50vw - 165px);
    // margin-top: calc(50vh - 230px);
    border-radius: 10px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 10px;
      right: 10px;
    }
    .form {
      padding: 20px 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      .form-item {
        width: 100%;
        padding: 10px 0;
        display: flex;
        label {
          span {
            display: inline-block;
            line-height: 35px;
            width: 100px;
            text-align: right;
            color: #666;
          }
        }
        > div {
          width: 100%;
          input {
            width: calc(100% - 20px);
            padding: 0 6px;
            border: 1px solid #333;
            height: 35px;
            border-radius: 4px;
            outline: none;
            transition: 0.3s all ease-out;
            color: #333;
            text-align: left;
            &:focus {
              box-shadow: 0px 0px 5px #333;
            }
            &::placeholder {
              color: #ccc;
            }
          }
        }
      }
    }
    .buttons {
      width: 100%;
      .to-regist {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        color: #666;
        a {
          color: #333;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
      }
      .button {
        padding: 20px 10px;
        display: flex;
        justify-content: center;
        span {
          color: #333;
          border-radius: 4px;
          border: 1px solid #333;
          padding: 5px 30px;
          transition: 0.3s all ease-out;
          user-select: none;
          cursor: pointer;
          &:hover {
            color: #333;
            border-color: #333;
          }
        }
      }
    }
  }
}
.b-login-wrap-mobile {
  width: 100%;
  height: calc(100% - 1.4rem);
  background: #fff;
  position: fixed;
  left: 0;
  top: 1.4rem;
  z-index: 999;
  .form {
    width: calc(100% - 1rem);
    padding: 1rem 0.5rem 0 0.5rem;
    margin-top: 10%;
    color: #666;
    li {
      margin-bottom: 0.6rem;
      border-bottom: 1px solid #333;
      text-align: left;
      label {
        font-size: 0.4rem;
      }
      > div {
        margin-top: 0.2rem;
        input {
          border: none;
          width: 100%;
          height: 0.5rem;
          border-radius: 0.1rem;
          outline: none;
          font-size: 0.3rem;
          color: #666;
          &:focus {
            border: none;
          }
          &::placeholder {
            font-size: 0.25rem;
            color: #ccc;
          }
        }
      }
    }
  }
  .to-regist {
    font-size: 0.25rem;
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    text-align: right;
    color: #666;
    a {
      color: #333;
    }
  }
  .buttons {
    width: calc(100% - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    span {
      width: 100%;
      text-align: center;
      margin-top: 0.7rem;
      text-align: center;
      background: #333;
      color: #fff;
      font-size: 0.4rem;
      padding: 0.1rem 0;
      border-radius: 0.1rem;
    }
  }
}

.login-fade-in-enter-active {
  transition: all 0.3s ease;
}
.login-fade-in-leave-active {
  transition: all 0.3s cubic-bezier(0.14, 0.5, 0.8, 1);
}
.login-fade-in-enter,
.login-fade-in-leave-to {
  transform: translate(10vw, 8vh) scale(0.8);
  opacity: 0;
}
.login-mobile-fade-in-enter-active {
  transition: all 0.3s ease;
}
.login-mobile-fade-in-leave-active {
  transition: all 0.3s cubic-bezier(0.14, 0.5, 0.8, 1);
}
.login-mobile-fade-in-enter,
.login-mobile-fade-in-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>