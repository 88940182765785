<template>
  <div class="index">
    <!-- banner -->
    <div class="banner">
      <div class="warp">
        <h1>{{$t('index.trust')}}</h1>
        <p>{{$t('index.advantage')}}</p>
      </div>
    </div>
    <!-- choose ALP -->
    <div class="choose ban">
      <div class="warp">
        <div class="titlee">{{$t('index.choose')}}
          <p>WHY TRADEST GCT Co., Limited</p>
        </div>
        <div class="paragraph">
          {{$t('index.dount')}}
        </div>
        <div class="square">
          <div @mouseenter="blur(1)" @mouseleave="blurOut" class="content">
            <div class="img-wrap">
              <img src="/img/trade1.png">
            </div>
            <p v-if=" index!==1 ">{{$t('index.safty')}}</p>
            <transition name="slide-fade">
              <div class="layer" v-show="index === 1" :style="{
                          width: width - 30 + 'px',
                          height: height + 'px'}">
                <p>{{$t('index.safty')}}</p>
                <div>
                  {{$t('index.connectionContent')}}
                </div>
              </div>
            </transition>
          </div>
          <div class="content" @mouseenter="blur(2)" @mouseleave="blurOut">
            <div class="img-wrap">
              <img src="/img/trade2.png">
            </div>
            <p v-if=" index!==2 ">{{$t('index.connection')}}</p>
            <transition name="slide-fade">
              <div class="layer" v-if="index === 2" :style="{
                        width: width - 30 + 'px',
                        height: height + 'px'}">
                <p>{{$t('index.connection')}}</p>
                <div>
                  {{$t('index.customerContent')}}
                </div>
              </div>
            </transition>
          </div>
          <div @mouseenter="blur(3)" @mouseleave="blurOut" class="content">
            <div class="img-wrap">
              <img src="/img/trade3.png">
            </div>
            <p v-if=" index!==3 ">{{$t('index.customer')}}</p>
            <transition name="slide-fade">
              <div class="layer" v-if="index === 3" :style="{
                        width: width - 30 + 'px',
                        height: height + 'px'}">
                <p>{{$t('index.customer')}}</p>
                <div>
                  {{$t('index.excellentContent')}}
                </div>
              </div>
            </transition>
          </div>
          <div @mouseenter="blur(4)" @mouseleave="blurOut" class="content">
            <div class="img-wrap">
              <img src="/img/trade4.png">
            </div>
            <p v-if=" index!==4 ">{{$t('index.excellent')}}</p>
            <transition name="slide-fade">
              <div class="layer" v-if="index === 4" :style="{
                          width: width - 30 + 'px',
                          height: height + 'px'}">
                <p>{{$t('index.excellent')}}</p>
                <div>

                  {{$t('index.customerContent')}}
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!--  platform-->
    <!-- <div class="platform ban">
      <div class="warp clearfix">
        <div class="titlee">{{$t('index.platform')}}
          <p>TRADING PLATFORM</p>
        </div>
        <div class="provide">
          <h5>{{$t('index.through')}}</h5>
          <ul>
            <li>{{$t('index.leverage')}}</li>
            <li>{{$t('index.profissional')}}</li>
            <li>{{$t('index.alone')}}</li>
            <li>{{$t('index.experience')}}</li>
          </ul>
          <h6>{{$t('index.desk')}}</h6>
          <p>
            {{$t('index.popular')}}
          </p>

        </div>
        <div class="hiddenXs">
          <img src="/img/diannao.png">
        </div>

      </div>

    </div> -->

    <!-- partner -->
    <div class="partner ban">
      <div class="warp">
        <div class="titlee">{{$t('index.partner')}}
          <p>COOPERATIVE PARTNER</p>
        </div>
        <el-row :gutter="20">
          <el-col :xs="12" :md="{span:4,offset:2}">
            <img src="/img/logo1.png">
          </el-col>
          <el-col :xs="12" :md="4">
            <img src="/img/logo2.png">
          </el-col>
          <el-col :xs="12" :md="4">
            <img src="/img/logo3.png">
          </el-col>
          <el-col :xs="12" :md="4">
            <img src="/img/logo4.png">
          </el-col>
          <el-col :xs="{span:12,offset:6}" :md="4">
            <img src="/img/logo5.png">
          </el-col>
        </el-row>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      index: -1,
      width: 0,
      height: 0,
    }
  },
  methods: {
    calStyle() {
      const squar = this.$el.querySelectorAll('.square >div')[this.index - 1];
      // const react = squar.getClientRects()[0];
      this.width = squar.offsetWidth
      this.height = squar.offsetHeight
    },
    blur(i) {
      this.$nextTick(() => {
        this.calStyle();
      });
      this.index = i;
    },
    blurOut() {
      this.index = -1
    }
  },
}
</script>

<style lang="scss">
.index {
  .hidden-md-and-up {
    display: none;
  }
  .banner {
    background-image: url(/img/beijing1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .warp {
      color: #fff;
      letter-spacing: 0.8em;
      text-align: left;
      width: calc(100% - 80px);
      padding: 0 40px;
      max-width: 1100px;
      h1 {
        font-size: 54px;
        font-weight: bold;
        text-align: left;
        padding-top: 10%;
        padding-bottom: 5%;
      }
      p {
        font-size: 28px;
        text-align: left;
        margin-bottom: 60px;
      }
      .locale-tip {
        color: #fff;
        position: absolute;
        letter-spacing: 2px;
        margin-left: 500px;
        margin-top: 60px;
        font-size: 18px;
      }
      .el-button {
        margin: 5% 0;
        padding: 16px 30px;
        letter-spacing: 0.2em;
        font-size: 18px;
        background-color: #3dd7ad;
      }
    }
  }

  .ban {
    padding: 5% 0;
    .titlee {
      text-align: center;
      font-size: 34px;
      color: #141828;
      font-weight: bold;
      letter-spacing: 2px;
      > p {
        font-size: 40px;
        font-weight: bold;
        color: rgba($color: #c1c1c1, $alpha: 0.2);
        margin-top: -28px;
        font-family: "akz";
      }
    }
    .paragraph {
      padding-top: 4%;
      font-size: 14px;
      color: gray;
      line-height: 30px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .choose {
    width: calc(100% - 80px);
    padding: 100px 40px;
    max-width: 1100px;
    margin: 0 auto;
    .square {
      width: 100%;
      display: flex;
      .layer {
        background: #fff;
        position: absolute;
        z-index: 1;
        opacity: 0.8;
        p {
          padding: 10px 0;
        }
        > div {
          font-size: 16px;
          text-align: left;
        }
      }
      .img-wrap {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all ease-out;
      }
      p {
        font-size: 20px;
        color: #333;
        font-weight: 600;
      }
      .content {
        margin-right: 20px;
        width: calc(25% - 20px);
        background: #fff;
        box-shadow: 2px 3px 12px 1px rgba(200, 200, 200, 0.33);
        min-height: 200px;
        margin-top: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      > div:last-child {
        margin-right: 0;
      }
      .contentbox {
        width: 100%;
        height: 100%;
        position: relative;
        > img {
          -webkit-filter: blur(10px); /* Chrome, Safari, Opera */
          filter: blur(10px);
        }
        > p {
          opacity: 0;
        }
        .contentxt {
          position: absolute;
          top: 0;
          left: 50px;
        }
      }
    }
  }

  .currency {
    background-image: url(/img/beijing3.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .warp {
      width: calc(100% - 80px);
      padding: 0 40px;
      max-width: 1100px;
    }
    .fiveLay {
      .sellCont {
        background: #fff;
        padding: 20px 40px;
        margin-top: 20px;
        > img {
          margin: 10px auto;
        }
        > p {
          font-size: 22px;
          color: #141828;
        }
      }
    }
  }

  .exchange {
    background-image: url(/img/beijing4.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    > .warp {
      padding: 3% 0;
    }
    .leftImg {
      float: left;
      width: 45%;
    }
    .rightText {
      float: left;
      width: 41%;
      > p {
        color: #b2c1e7;
        font-size: 14px;
        text-align: left;
        line-height: 30px;
      }
      & :first-child {
        color: #fff;
        font-size: 34px;
        line-height: 70px;
        margin-bottom: 3%;
        > span {
          color: rgba($color: #ffffff, $alpha: 0.5);
        }
      }
    }
  }

  .platform {
    background-image: url(/img/beijing5.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .provide {
      float: left;
      width: 45%;
      margin-top: 5%;
      text-align: left;
      font-size: 16px;
      color: #273863;
      line-height: 30px;
      > h5 {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 20px;
      }
      > ul {
        list-style-type: disc;
        padding-left: 20px;
      }
      > h6 {
        font-size: 28px;
        line-height: 50px;
        margin-top: 4%;
      }
      .el-button {
        background-color: #333;
        color: #fff;
        padding: 18px 60px;
        font-size: 16px;
        border-radius: 8px;
        margin: 4% 0;
      }
    }
    .hiddenXs {
      float: left;
      width: 55%;
      margin-top: 8%;
      > img {
        width: 90%;
      }
    }
  }

  .partner {
    .el-row {
      margin-top: 40px;
      .el-col {
        margin-top: 20px;
      }
    }
  }

  .joinUs {
    background-color: #f0f3fa;
    .bgImg1 {
      background-image: url(/img/img1.png);
      width: 204px;
      height: 157px;
      background-repeat: no-repeat;
    }
    .bgImg2 {
      background-image: url(/img/img2.png);
      width: 204px;
      height: 157px;
      background-repeat: no-repeat;
    }
    .bgImg3 {
      background-image: url(/img/img3.png);
      width: 204px;
      height: 157px;
      background-repeat: no-repeat;
    }
    .bgImg4 {
      background-image: url(/img/img4.png);
      width: 204px;
      height: 157px;
      background-repeat: no-repeat;
    }
    .box {
      font-size: 20px;
      color: #7586b0;
      padding: 30px;
      .bgImg > p {
        font-size: 26px;
        color: #2a447b;
        font-weight: bold;
        padding: 20px 0 10px 0;
      }
      .bgImg > span {
        font-size: 18px;
      }
    }
    .el-button {
      background-color: #333;
      color: #fff;
      padding: 20px 150px;
      font-size: 16px;
      border-radius: 8px;
      margin-top: 4%;
    }
  }

  .hidbom {
    display: none;
  }
}
.slide-fade-enter-active {
  opacity: 0;
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(40px);
}
</style>