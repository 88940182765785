<template>
  <div class="troduction">
    <!-- banner -->
    <div class="backImg">
      <div class="bannerTit">{{$t('troduction.btx')}}
        <!-- <p>GCT Co., Limited TRODUCTION</p> -->
      </div>
    </div>

    <!-- ALP -->
    <div class="btx">
      <div class="warp">
        <div class="distance clearfix">
          <p class="headTitle">GCT Co., Limited</p>
          <div class="text">
            <p>{{$t('troduction.brand')}}</p>

            <p>{{$t('troduction.pioneer')}}</p>

            <p class="enpcHidden">{{$t('troduction.spread')}}</p>
          </div>
          <div class="img">
            <img src="/img/jianjie.jpg">
          </div>
          <div class="clearfix"></div>
          <p class="enpcshow en-show">{{$t('troduction.spread')}}</p>

        </div>

      </div>

    </div>

    <!-- team -->
    <div class="team ">
      <div class="warp">
        <div class="distance">
          <el-row>
            <el-col :xs="24" :md="12">
              <div class="serve">
                <p class="headTitle">{{$t('troduction.team')}}</p>
                <img src="/img/tuandui.jpg">
                <p class="para">{{$t('troduction.financial')}}
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :md="12">
              <div class="serve">
                <p class="headTitle">{{$t('troduction.service')}}</p>
                <img src="/img/fuwu.jpg">
                <p class="para">{{$t('troduction.help')}}
                </p>
              </div>
            </el-col>
          </el-row>
          <div class="serve margin">
            <p class="headTitle">{{$t('troduction.vision')}}</p>
            <img class="show_pc" src="/img/yuanjing.jpg">
            <img class="show_mobile" src="/img/yuanjing_mobile.jpg">
            <p class="para center">{{$t('troduction.uphold')}}
            </p>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.troduction {
  .show_mobile {
    display: none;
  }
  .para {
    color: #273863;
    line-height: 23px;
    text-align: justify;
    width: 85%;
    margin: 10px auto;
  }

  .btx {
    .en-show {
      display: none;
    }
    .warp {
      .headTitle {
        text-align: left;
      }
      .text {
        color: #273863;
        line-height: 23px;
        float: left;
        width: 40%;
        text-align: justify;
        > p {
          margin-bottom: 10px;
        }
      }
      .img {
        width: 60%;
        float: right;
      }
    }
  }
  .team {
    background-color: #f0f3fa;
    .center {
      text-align: center;
    }
    .margin {
      margin-top: 40px;
    }
  }
}
</style>