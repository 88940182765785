<template>
  <div class="header">
    <div class="header-space">

    </div>
    <header>
      <div class="warp clearfix">
        <div class="logo" style="height: 60px">
          <router-link to="/" style="display: block; height: 100%; text-align: center">
            <img src="/img/logo.png" style="height: 35px; display: block; margin: 5px auto 0">
            <div style="line-height: initial; font-size: 12px">GCT Co., Limited</div>
          </router-link>
        </div>
        <div class="menu-wrap pc">
          <ul>
            <li>
              <router-link to="/">{{ $t('header.pc.home') }}</router-link>
            </li>
            <li>
              <router-link to="/troduction">{{ $t('header.pc.aboutUs') }}</router-link>
            </li>
            <!--<li
                @mouseenter.stop="showAbout=true"
                @mouseleave.stop="showAbout=false">
                    {{$t('header.pc.aboutUs')}}
                <transition name="header-slide-fade">
                    <ul v-show="showAbout" :class="`sub-menu about-us`" :style="{marginLeft: $i18n.locale==='en' ? '-25px': ''}">
                        <li>
                            <router-link to="/troduction">{{$t('header.pc.introduction')}}</router-link>
                        </li>
                        <li>
                            <router-link to="/regulatory">{{$t('header.pc.authority')}}</router-link>

                        </li>
                    </ul>
                </transition>
            </li>-->
            <li>
              <router-link to="/choose">{{ $t('header.pc.choose') }}</router-link>
            </li>
            <li>
              <router-link to="/tools">{{ $t('header.pc.and') }}</router-link>
            </li>
            <!--<li @mouseenter.stop="showProduct=true"
                @mouseleave.stop="showProduct=false">
                    {{$t('header.pc.and')}}
                <transition name="header-slide-fade">
                    <ul v-show="showProduct" :class="`sub-menu product`" style="margin-left: -50px">
                        <li>
                            <router-link to="/tools">
                                {{$t('header.pc.tools')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/product">
                                {{$t('header.pc.tradding')}}
                            </router-link>
                        </li>
                    </ul>
                </transition>
            </li>-->
            <!-- <li>
              <router-link to="/contactUs">{{ $t('header.pc.contact') }}</router-link>
            </li> -->

          </ul>
          <div class="btn-wrap">

            <!--            <el-dropdown trigger="hover" class="dropdown" @command="changeLanguage">-->
            <!--              <div class="language-wrap">-->
            <!--                <img src="/img/shijie.png" class="icon">-->
            <!--                <span class="el-dropdown-link">-->
            <!--                                    {{ $t('header.pc.chinese') }}-->
            <!--                                    <i class="el-icon-caret-bottom el-icon&#45;&#45;right"></i>-->
            <!--                                </span>-->
            <!--              </div>-->
            <!--              <el-dropdown-menu slot="dropdown">-->
            <!--                <el-dropdown-item command="cn">-->
            <!--                  <div class="language-wrap">-->
            <!--                    <img src="/img/china.png">-->
            <!--                    {{ $t('header.pc.tradational') }}-->
            <!--                  </div>-->
            <!--                </el-dropdown-item>-->
            <!--                <el-dropdown-item command="en">-->
            <!--                  <div class="language-wrap">-->
            <!--                    <img src="/img/UK.png">-->
            <!--                    {{ $t('header.pc.english') }}-->
            <!--                  </div>-->
            <!--                </el-dropdown-item>-->
            <!--              </el-dropdown-menu>-->
            <!--            </el-dropdown>-->
          </div>
        </div>
        <div class="mobile">
          <!--<div class="login" @click="$root.$emit('showLogin')">
              {{$t('header.pc.login')}}
          </div>-->
          <ul class="tab" @click.stop="showMenu=!showMenu">
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <transition name="header-slide-fade">
            <ul v-if="showMenu" class="mobile-menu-list">
              <li @click.stop="rotuerTo('/')">
                {{ $t('header.pc.home') }}
              </li>
              <li @click.stop="rotuerTo('/troduction')">
                {{ $t('header.pc.aboutUs') }}
              </li>
              <!--<li
                  @click.stop="showMenuChange('showAbout')">
                  <div>
                      <span>
                          {{$t('header.pc.aboutUs')}}
                      </span>
                      <i
                          class="el-icon-arrow-right"
                          :class="{active: showAbout}"></i>
                  </div>
                  <transition name="header-slide-fade">
                      <ul v-show="showAbout" class="sub-menu">
                          <li @click.stop="rotuerTo('/troduction')">
                              {{$t('header.pc.introduction')}}
                          </li>
                          <li @click.stop="rotuerTo('/regulatory')">
                              {{$t('header.pc.authority')}}
                          </li>
                      </ul>
                  </transition>
              </li>-->
              <li @click.stop="rotuerTo('/choose')">
                {{ $t('header.pc.choose') }}
              </li>
              <li @click.stop="rotuerTo('/tools')">
                {{ $t('header.pc.and') }}
              </li>
              <!--<li @click.stop="showMenuChange('showProduct')">
                  <div>
                      <span>
                          {{$t('header.pc.and')}}
                      </span>
                      <i
                          class="el-icon-arrow-right"
                          :class="{active: showProduct}"></i>
                  </div>
                  <transition name="header-slide-fade">
                      <ul v-show="showProduct" class="sub-menu">
                          <li @click.stop="rotuerTo('/tools')">
                              {{$t('header.pc.tools')}}
                          </li>
                          <li @click.stop="rotuerTo('/product')">
                              {{$t('header.pc.tradding')}}
                          </li>
                      </ul>
                  </transition>
              </li>-->
              <li @click.stop="rotuerTo('/contactUs')">
                {{ $t('header.pc.contact') }}
              </li>
              <li class="space"></li>
              <li class="language" @click.stop="showMenuChange('showLanguage')">
                <div>
                  <span>
                    {{ $t('header.languageSetting') }}
                  </span>
                  <i class="el-icon-arrow-right" :class="{active: showLanguage}"></i>
                </div>
                <transition name="header-slide-fade">
                  <ul @click.stop v-show="showLanguage" class="language-list">
                    <li @click="changeLanguage('cn')" :class="{active: $i18n.locale=='cn'}">
                      简体中文
                    </li>
                    <li @click="changeLanguage('en')" :class="{active: $i18n.locale=='en'}">
                      English
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </transition>
        </div>
      </div>
    </header>
    <Login />
  </div>
</template>

<script>
import Login from './login.vue';

export default {
  components: { Login },
  data() {
    return {
      showMenu: false,
      showProduct: false,
      showAbout: false,
      showLanguage: false,
      keys: ['showAbout', 'showProduct', 'showLanguage'],
    };
  },
  watch: {
    showMenu() {
      this.keys.forEach(el => this[el] = false);
      const body = document.querySelector('body');
      body.style.touchAction = this.showMenu ? 'none' : 'unset';
      body.style.overflow = this.showMenu ? 'hidden' : 'unset';
      body.style.position = this.showMenu ? 'fixed' : 'relative';
    },
    $route() {
      this.showMenu = false;
    }
  },
  methods: {
    toRegist() {
      this.$util.open('regist');
    },
    rotuerTo(path) {
      this.showMenu = false;
      if (path === this.$route.fullPath) {
        return
      }
      this.$router.push(path);
    },
    showMenuChange(key) {
      this.keys.forEach(el => this[el] = el === key)
    },
    changeLanguage(type) {
      this.$util.locale(type);
      this.$i18n.locale = type;
      this.showMenu = false;
    }
  }
}
</script>

<style lang="scss">
.language-wrap {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.header {
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  .header-space {
    width: 100%;
    height: 100%;
  }

  header {
    width: 100%;
    height: 60px;
    left: 0;
    top: 0;
    z-index: 100;
    position: fixed;
    // background: #075c8f;
    background: #fff;
    color: #333;
    width: calc(100% - 80px);
    padding: 0 40px;

    > div {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      .logo {
        line-height: 60px;
        width: 150px;

        img {
          height: 50px;
          vertical-align: middle;
        }
      }

      .menu-wrap {
        width: calc(100% - 100px);
        height: 100%;
        display: flex;

        > ul {
          display: flex;
          width: 100%;
          // justify-content: flex-end;
          justify-content: space-around;
          align-items: center;

          > li {
            padding: 10px 20px;
            user-select: none;
            transition: 0.3s all ease-out;

            &:hover {
              // color: #8ba9e7;
              color: #1d2d8c;
            }
          }

          .sub-menu {
            position: absolute;
            background: #333;
            margin-top: 20px;
            box-shadow: 0px 0px 10px rgb(206, 202, 202);
            border-radius: 4px;
            -ms-transform: translate(-0px, 10px);

            &::before {
              content: " ";
              width: 0;
              height: 0;
              border-width: 15px;
              border-style: solid;
              border-color: transparent transparent #333 transparent;
              position: absolute;
              margin-top: -28px;
              margin-left: -40px;
              -ms-transform: translate(70px, 0);
            }

            li {
              padding: 5px 10px;
              text-align: left;
              transition: 0.3s all ease-out;
              cursor: pointer;
              color: #fff;

              &:hover {
                color: #8ba9e7;
              }
            }
          }

          .product {
            -ms-transform: translate(40px, 20px);
          }
        }

        .btn-wrap {
          width: 400px;
          margin-left: 50px;
          display: flex;
          justify-content: flex-end;

          .button {
            line-height: 60px;
            color: #3ccfaa;
            user-select: none;
            cursor: pointer;

            > a {
              float: left;
              vertical-align: middle;
              margin-right: 20px;
              transition: 0.3s all ease-out;

              &:hover {
                color: #fff;
              }
            }
          }

          .dropdown {
            float: right;
            line-height: 60px;
            vertical-align: middle;
            font-size: 16px;

            .el-dropdown-menu__item {
              img {
                margin-right: 10px;
              }
            }
          }

          .el-dropdown-link {
            cursor: pointer;
            color: #fff;
          }

          .el-icon-arrow-down {
            font-size: 12px;
          }

          .demonstration {
            display: block;
            color: #8492a6;
            font-size: 14px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.header-slide-fade-enter-active {
  transition: all 0.3s ease;
}

.header-slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.14, 0.5, 0.8, 1);
}

.header-slide-fade-enter,
.header-slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
