<template>
  <div class="choose">
    <!-- banner -->
    <div class="backImg">
      <div class="bannerTit">{{$t('choose.choose')}}
        <!-- <p>WHY TRADE ST GCT Co., Limited</p> -->
      </div>
    </div>

    <!-- 安全 稳定 -->
    <div class="stable">
      <div class="warp">
        <div class="distance clearfix">
          <div class="hold">
            <p class="fontSize">{{$t('choose.hold')}}</p>
            <p>{{$t('choose.regulated')}}</p>
          </div>
          <div class="circle">
            <!--                        <img :src="`/img/choose${$i18n.locale}.png`">-->
          </div>
        </div>
      </div>
    </div>

    <!-- 安全保障 -->
    <div class="guarantee">
      <div class="warp">
        <div class="distance">
          <p class="headTitle">{{$t('choose.protection')}}</p>
          <!--<p class="text">{{$t('choose.bank')}} -->
          </p>
          <el-row :gutter="30">
            <el-col :xs="12" :md="6">
              <div class="square">
                <img src="/img/choose6.png">
                <p>{{$t('choose.jianguan')}}</p>
                <span>{{$t('choose.international')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <img src="/img/choose2.png">
                <p>{{$t('choose.data')}}</p>
                <span>{{$t('choose.per')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <img src="/img/choose5.png">
                <p>{{$t('choose.money')}}</p>
                <span>{{$t('choose.isolation')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <img src="/img/choose3.png">
                <p>{{$t('choose.information')}}</p>
                <span>{{$t('choose.profess')}}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 超速服务器 -->
    <div class="server">
      <div class="warp">
        <div class="distance">
          <p class="headTitle">
            {{$t('choose.speed')}}
          </p>
          <p class="text">{{$t('choose.optical')}}
          </p>
          <el-row :gutter="30">
            <el-col :xs="12" :md="6">
              <div class="square">
                <p>{{$t('choose.over')}}</p>
                <h6>99.99%</h6>
                <span>{{$t('choose.stability')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <p>{{$t('choose.promote')}}</p>
                <h6>78%</h6>
                <span>{{$t('choose.execution')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <p>{{$t('choose.up')}}</p>
                <h6>27</h6>
                <span>{{$t('choose.fluidity')}}</span>
              </div>
            </el-col>
            <el-col :xs="12" :md="6">
              <div class="square">
                <p>{{$t('choose.minute')}}</p>
                <h6>10000</h6>
                <span>{{$t('choose.update')}}</span>
              </div>
            </el-col>
          </el-row>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.choose {
  .stable {
    background-image: url(/img/choose1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .hold {
      float: left;
      width: 30%;
      margin-top: 18%;
      p {
        font-size: 18px;
        line-height: 30px;
        // color: #fff;
        color: #333;
        text-align: left;
      }
      .fontSize {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .circle {
      float: right;
      width: 60%;
      img {
        width: 100%;
      }
    }
  }
  .guarantee {
    .text {
      font-size: 20px;
      color: #828fae;
      line-height: 37px;
      width: 80%;
      margin: 20px auto;
    }
    .square {
      padding: 30px;
      box-shadow: 2px 3px 12px 1px rgba(200, 200, 200, 0.33);
      margin-top: 20px;
      min-height: 180px;
      > p {
        font-size: 18px;
        color: #333;
        line-height: 30px;
        font-family: "pingfangBold";
        margin: 20px 0;
      }
      > span {
        color: #a1afd0;
        line-height: 20px;
      }
    }
  }
  .server {
    background-image: url(/img/choose.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .headTitle {
      // color: #fff;
      color: #333;
    }
    .text {
      font-size: 20px;
      // color: #fff;
      color: #333;
      line-height: 37px;
      margin: 20px auto;
    }
    .square {
      background: #fff;
      padding: 20px;
      color: #333;
      height: 190px;
      > p {
        font-size: 20px;
        line-height: 30px;
      }
      > h6 {
        font-size: 39px;
        font-family: "wryh";
        font-weight: bold;
        margin: 20px 0;
      }
      > span {
        font-size: 24px;
        color: #667596;
      }
    }
  }
}
</style>
