<template>
  <div class="contactUs">
    <div class="warp">
      <div class="distance">
        <div class="bannerTit">{{ $t('contact.us') }}
          <p>CONTACT US</p>
        </div>
        <div class="backgroundW clearfix">
          <div class="email clearfix">
            <img src="/img/Mail.png">
            <p>{{ $t('contact.email') }}</p>
            <p><a href="mailto:it02@matrifx .com">it02@matrifx .com</a></p>
          </div>
          <div class="email clearfix">
            <img src="/img/skype.png">
            <p>{{ $t('contact.tel') }}</p>
            <p>+852 69583342</p>
          </div>
          <!--                    <div class="email clearfix">-->
          <!--                        <img src="/img/dizhi.png">-->
          <!--                        <p>{{$t('contact.address')}}</p>-->
          <!--                        <p>Room 1405， 14/F ，Lucky Centre， 165 Wanchai ROAD， Wan Chai ，Hong Kong</p>-->
          <!--                    </div>-->

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.contactUs {
  background: url(/img/contact_pc.jpg) no-repeat;
  background-size: 100% 100%;
  height: 578px;

  .bannerTit {
    text-align: center;
    font-size: 55px;
    color: #333;
    padding-top: 4%;

    > p {
      font-size: 55px;
      font-weight: bold;
      color: #333;
      opacity: 0.2;
      margin-top: -42px;
      font-family: "akz";
    }
  }

  .backgroundW {
    background: #fff;
    padding: 40px;
    font-size: 16px;
    color: #6f6f6f;
    margin: 50px auto;
    display: flex;
    justify-content: space-around;

    .email {
      float: left;
      width: 33%;

      > p {
        margin: 20px auto;
      }
    }
  }
}
</style>