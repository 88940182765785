<template>
  <div class="product">
    <!-- banner -->
    <div class="backImg">
      <div class="bannerTit">{{$t('product.trade')}}
        <p>TRADING PRODUCTS</p>
      </div>
    </div>

    <!-- 加密货币 -->
    <div class="currency">
      <div class="warp">
        <div class="distance">
          <p class="headTitle">{{$t('product.what')}}</p>
          <p class="graph">{{$t('product.jiamim')}}
          </p>
          <p class="graph">{{$t('product.investor')}}
          </p>
          <div class="">
            <el-row>
              <el-col :xs="24" :md="8">
                <div class="blueBg">
                  <p>{{$t('product.reality')}}</p>
                  <span>{{$t('product.unstable')}}
                  </span>
                </div>
              </el-col>
              <el-col :xs="24" :md="{span:8,offset:7}">
                <div class="blueBg">
                  <p>{{$t('product.attitude')}}</p>
                  <span>{{$t('product.positive')}}
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="rowBg">
                  <img src="img/yingxiangyinsu.png">
                  <p>{{$t('product.influence')}}<br>{{$t('product.factor')}}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="24" :md="8">
                <div class="blueBg marginTop">
                  <p>{{$t('product.out')}}</p>
                  <span>{{$t('product.all')}}
                  </span>
                </div>
              </el-col>
              <el-col :xs="24" :md="{span:8,offset:7}">
                <div class="blueBg marginTop">
                  <p>{{$t('product.industry')}}</p>
                  <span>{{$t('product.trend')}}

                  </span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>

    </div>

    <!-- 加密货币优势 -->
    <div class="advantage">
      <div class="warp">
        <div class="distance">
          <p class="headTitle">{{$t('product.advantage')}}</p>
          <el-row :gutter="20">
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">01</p>
                <p>{{$t('product.two')}}</p>
              </div>
            </el-col>
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">02</p>
                <p>{{$t('product.hdging')}}</p>
              </div>
            </el-col>
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">03</p>
                <p>{{$t('product.low')}}</p>
              </div>
            </el-col>
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">04</p>
                <p>{{$t('product.small')}}</p>
              </div>

            </el-col>
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">05</p>
                <p>{{$t('product.super')}}</p>
              </div>
            </el-col>
            <el-col :xs="12" :md="8">
              <div class="white">
                <p class="number">06</p>
                <p>{{$t('product.no')}}</p>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>

    </div>

    <!-- 热门交易成本 -->
    <div class="kind">
      <div class="warp">
        <div class="distance">
          <p class="headTitle">{{$t('product.hot')}}</p>
          <p class="gray">{{$t('product.service')}}</p>
          <div class="fiveLay">
            <el-row :gutter="20">
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency1.png">
                  <p>ALP</p>
                </div>
              </el-col>
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency2.png">
                  <p>ETH</p>
                </div>
              </el-col>
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency3.png">
                  <p>BCH</p>
                </div>
              </el-col>
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency4.png">
                  <p>LTC</p>
                </div>
              </el-col>
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency5.png">
                  <p>EOS</p>
                </div>
              </el-col>
              <el-col :xs="8" :md="4">
                <div class="sellCont">
                  <img src="/img/currency6.png">
                  <p>XRP</p>
                </div>
              </el-col>

            </el-row>
          </div>
          <div class="details" @click="viewImage">{{$t('product.click')}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    viewImage() {
      this.$root.$emit('viewFullScreenImage', `/img/details_${this.$i18n.locale}.jpg`);
    }
  }
}
</script>

<style lang="scss">
.product {
  .currency {
    background-image: url(/img/tools1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    .headTitle {
      color: #fff;
      font-size: 30px;
      font-family: "pingfangBold";
      letter-spacing: 2px;
    }
    .graph {
      font-size: 14px;
      font-family: "pingfangMedium";
      line-height: 26px;
      width: 80%;
      margin: 20px auto;
    }
    .rowBg {
      margin-top: -40px;
      position: relative;
      > p {
        position: absolute;
        font-size: 22px;
        font-family: "pingfangBold";
        color: #7bebff;
        line-height: 30px;
        left: 41%;
        top: 39%;
      }
    }
    .blueBg {
      background-image: url(/img/tools3.png);
      width: 80%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: left;
      padding: 20px;
      margin: 10px;
      p {
        font-size: 20px;
        font-family: "pingfangBold";
        line-height: 30px;
        margin-bottom: 5px;
      }
      span {
        font-size: 18px;
        font-family: "pingfangMedium";
        line-height: 24px;
      }
    }
    .marginTop {
      margin-top: -40px;
    }
  }

  .advantage {
    background-image: url(/img/tools.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .distance {
      .el-row {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .white {
      background: #fff;
      padding: 40px 20px;
      margin-top: 30px;
      font-size: 18px;
      color: #333;
      font-weight: bold;
      .number {
        color: #5b78ba;
        font-size: 60px;
        line-height: 30px;
        font-family: "akz";
        margin-bottom: 40px;
      }
    }
  }

  .kind {
    background: url(/img/beijing3.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .hidden-md-and-up {
      display: none;
    }
    .distance {
      padding: 70px 0;
      .gray {
        color: #8a8a8a;
      }
    }
    .fiveLay {
      margin-top: 50px;
      .sellCont {
        background: #fff;
        padding: 20px 40px;
        margin-top: 20px;
        > img {
          margin: 10px auto;
          width: 100%;
        }
        > p {
          font-size: 22px;
          color: #141828;
        }
      }
    }
    .details {
      border: 1px solid #5b78ba;
      border-radius: 4px;
      width: 60%;
      line-height: 50px;
      color: #333;
      font-size: 18px;
      margin: 50px auto;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>