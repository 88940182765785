<!--
 * @Author: your name
 * @Date: 2021-03-18 15:31:53
 * @LastEditTime: 2021-03-18 16:12:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \btx101\src\App.vue
-->
<template>
  <div id="app" :class="className">
    <header-app></header-app>
    <transition name="router-fade-in">
      <router-view />
    </transition>
    <footer-app></footer-app>

  </div>
</template>

<script>
import headerApp from './components/header'
import footerApp from './components/footer'
export default {
  components: {
    headerApp, footerApp,
    // viewImageFullscreen
  },
  computed: {
    className() {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: "pingfang";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  overflow: auto;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.router-fade-in-enter-active {
  transition: all 0.1s ease-in;
  opacity: 0;
}
.router-fade-in-leave-active {
  transition: all 0.1s ease-in;
  opacity: 0;
}
.router-fade-in-enter,
.router-fade-in-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
