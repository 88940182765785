<template>
  <div>
    <footer>
      <div class="warp">
        <div class="clearfix">
          <div class="logo">
            <img src="/img/logo.png" style="height: 60px; width: auto">
            <div style="margin-top: 5px">GCT Co., Limited</div>

          </div>
          <ul class="clearfix menu-list">
            <li>
              <router-link to="/">{{$t('header.pc.home')}}</router-link>
            </li>
            <li>
              <router-link to="/troduction">{{$t('header.pc.aboutUs')}}</router-link>
            </li>
            <!--<li
                            @mouseenter.stop="showAbout=true"
                            @mouseleave.stop="showAbout=false">
                                {{$t('header.pc.aboutUs')}}
                            <transition name="header-slide-fade">
                                <ul v-if="showAbout" class="sub-menu" :style="{marginLeft: $i18n.locale==='en' ? '-25px': '-30px'}">
                                    <li>
                                        <router-link to="/troduction">{{$t('header.pc.introduction')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/regulatory">{{$t('header.pc.authority')}}</router-link>

                                    </li>
                                </ul>
                            </transition>
                        </li>-->
            <li>
              <router-link to="/choose">{{$t('header.pc.choose')}}</router-link>
            </li>
            <!--<li @mouseenter.stop="showProduct=true"
                            @mouseleave.stop="showProduct=false">
                                {{$t('header.pc.and')}}
                            <transition name="header-slide-fade">
                                <ul v-if="showProduct" class="sub-menu" :style="{marginLeft: $i18n.locale === 'cn' ? '-28px' : ''}">
                                    <li>
                                        <router-link to="/tools">
                                            {{$t('header.pc.tools')}}
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/product">
                                            {{$t('header.pc.tradding')}}
                                        </router-link>
                                    </li>
                                </ul>
                            </transition>
                        </li>-->
            <li>
              <router-link to="/tools">{{$t('header.pc.and')}}</router-link>
            </li>
            <!-- <li>
              <router-link to="/contactUs">{{$t('header.pc.contact')}}</router-link>
            </li> -->
            <!-- <li>{{$t('footer.ALP')}}</li>
                        <li>{{$t('footer.xuanze')}}</li>
                        <li>{{$t('footer.and')}}</li>
                        <li>{{$t('footer.us')}}</li> -->
          </ul>
        </div>
        <!-- <div class="contact">
          <span>{{$t('footer.email')}}<a href="mailto:it02@matrifx .com">it02@matrifx
              .com</a>{{$t('footer.reply')}}）</span>
          <span>{{$t('footer.skype')}}</span><br>
       
        </div> -->
        <!-- <p>{{$t('footer.risk')}}</p> -->
        <p>{{$t('footer.risk2')}} <span style="padding-left: 50px; cursor: pointer"
            @click="statementHandle">{{$t('statement.title')}}</span></p>

      </div>

    </footer>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showProduct: false,
      showAbout: false,
    };
  },
  methods: {
    statementHandle() {
      window.open(location.origin + '/#/' + 'statement')
    }
  }
}
</script>

<style lang="scss">
footer {
  background-color: #333;
  padding: 3% 0;
  font-size: 14px;
  color: #fff;
  .warp {
    max-width: 1100px;
    .menu-list {
      li {
        transition: 0.3s all ease-out;
        user-select: none;
        cursor: pointer;
        &:hover {
          color: #8ba9e7;
        }
        .sub-menu {
          position: absolute;
          background: #172239;
          margin-top: 20px;
          box-shadow: 0px 0px 10px #0d40af;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          &::after {
            content: " ";
            width: 0;
            height: 0;
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent #172239 transparent;
            position: absolute;
            margin-top: -28px;
            margin-left: 40px;
            -ms-transform: translate(0, -50px);
          }
          li {
            padding: 5px 10px;
            margin: 0;
            text-align: left;
            transition: 0.3s all ease-out;
            cursor: pointer;
            color: #fff;
            &:hover {
              color: #8ba9e7;
            }
          }
        }
      }
    }
    .logo,
    ul {
      float: left;
    }
    .logo {
      width: 40%;
    }
    ul li {
      float: left;
      padding: 25px 20px;
      // margin:30px;
    }
    .contact {
      margin-top: 40px;
      > span {
        margin: 30px;
        line-height: 40px;
      }
      a {
        color: #29b99d;
        text-decoration: underline;
      }
    }
    > p {
      width: 80%;
      color: #5770a3;
      font-size: 12px;
      margin: 0 auto;
      margin-top: 3%;
    }
  }
}
</style>
