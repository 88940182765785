module.exports = {
  header: {
    languageSetting: '语言设置',
    pc: {
      home: '首页',
      introduction:'GCT Co., Limited 介绍',
      choose:'选择 GCT',
      tools:'交易工具 -  ',
      contact:'联系我们',
      register:'注册',
      login:'登录',
      chinese:'简体中文',
      tradational:'简体中文',
      english:'英文',
      aboutUs: '关于我们',
      authority: '监管机构',
      tradding: '交易产品 - 金融软件',
      and:'产品与工具',
    },
    login:{
      userName:'用户名',
      enterUserName:'请输入用户名',
      password:'密码',
      enterPassword:'请输入密码',
      noAccound:'没有 GCT Co., Limited 账户？',
      signUp:'立即注册',
      login:'登录',
    },
  },
  //footer
  footer:{
    btx:'GCT Co., Limited 简介',
    xuanze:'选择 GCT Co., Limited',
    and:'产品与交易',
    us:'联系我们',
    email:'邮箱 : ',
    reply:'（24小时高效回复',
    skype:'电话 : +852 69583342',
    park:'地址 : Room 1405， 14/F ，Lucky Centre， 165 Wanchai ROAD， Wan Chai ，Hong Kong',
    risk:'*风险提示：GCT Co., Limited(以下称: GCT)提醒您, 保证金交易及买卖存在的高风险, 有可能招致超出您的存款的损失. 您并非实际拥有或持有任何相关基础资产. 请在交易前仔细阅读我们的产品披露声明. 敬请在交易前完全了解所涉及的风险, 并谨慎管理风险敞口.',
	risk2: 'Copyright © 2019-2020 GCT Co., Limited 版权所有不得转载'

  },
  // 首页
  index:{
    trust:'您的信赖，我们全力以赴',
    advantage:'发现我们的优势，与 GCT Co., Limited 共赴全球金融软件市场',
    alive:'注册真实账户',
    choose:'选择 GCT Co., Limited',
    locale: '*您当前自动匹配的usdt服务商地区： 中国',
    dount:'GCT Co., Limited 深知交易者身处金融市场不同阶段的疑惑及顾虑，因此在建立之初，我们严格整合了资深的环球专业团队，明确了以重建客户信心、降低客户风险为目标的企业发展宗旨，秉承安全规范、诚信可靠的创立初衷；不断创新、持续发展，GCT全力为客户提供安全的操作系统、完备的交易工具、丰富的金融资源以及专业的全球支持，营造最佳的交易体验，力求发展成为标准化规范化的国际领先金融软件交易商。',
    safty:'资金安全',
    saftyContent: '客户资金与公司自身资金存放于不同银行账户，以达到完全分离独立的行业安全准则',
    connection:'连接全球市场',
    connectionContent: '主流的金融软件品种，极具竞争力的交易环境，GCT Co., Limited 带您进去全球金融软件市场。',
    customer:'客户专享服务',
    customerContent: '是全球交易市场首选的在线交易平台，GCT Co., Limited 为您提供桌面版、移动版的多样选择。',
    excellent:'优秀交易平台',
    excellentContent: '专业服务、客户至上，周一至周五，全天24小时专业客服，GCT Co., Limited 随时为您答疑解惑。',
    currency:'交易币种',
    provide:'GCT Co., Limited 提供多种金融软件s交易产品，通过进行交易，具有低延时，极具竞争力的价格和机构级的执行效率等优点。',
    sell:'卖出',
    buy:'买入',
    highest:'最高',
    lowest:'最低',
    fast:'兑币便捷',
    exchange:' / FAST EXCHANGE',
    accounding:'根据用户所在的国家自动匹配当地usdt交易服务商兑币国内即可完成交易，目前开通的国家：美国、加拿大、澳大利亚、英国、法国、俄罗斯、中国、中国香港、日本。',
    platform:'交易平台',
    through:'GCT Co., Limited 通过 交易工具为客户提供：',
    crypt:'加密货币差价合约',
    leverage:'灵活交易杠杆',
    profissional:'专业技术分析，涵盖40多种技术指标及分析工具',
    alone:'独立订单及账户交易系统，包含四种订单交易模式',
    experience:'便捷移动端交易体验，同时使用智能手机',
    desk:'桌面版',
    popular:'作为最受欢迎的交易工具，在与 GCT Co., Limited 的紧密合作中，为成功投资者带来一套便捷且功能齐全的交易解决方案，是现代交易者的最佳选择。桌面版交易系统适用于您的各类电脑设备。',
    downland:'立即下载',
    partner:'合作伙伴',
    join:'加入我们',
    register:'注册',
    trade:'注册交易账户',
    verification:'验证',
    file:'验证您的文件',
    if:'是否符合要求',
    funds:'资金',
    deposit:'入金至您的',
    in:'交易账户内',
    jiao:'交易',
    start:'开始交易加密',
    cfd:'货币差价合约',
  },
  //选择GCT
  choose:{
    choose:'选择 GCT Co., Limited',
    hold:'持牌，安全，稳定',
    regulated:'受到全面监管且获得全球信赖!当您选 GCT Co., Limited 时，您是选择在一个受到全面监管的行业领导平台上进行交易',
    protection:'安全保障',
    bank:'GCT Co., Limited 获得美国MSB监管许可，资金监管与Ernst & Young国际会计事务所监管合作，采取资金隔离，信息加密，资金监控等措施确保客户资金安全。',
    jianguan:'监管保障',
    international:'国际牌照及监管',
    data:'数据安全',
    per:'每笔订单公开透明安全可查询',
    money:'资金安全',
    isolation:'资金隔离与安全保障',
    information:'信息安全',
    profess:'专业技术加密保护信息安全',
    speed:'超速服务器',
    optical:'GCT Co., Limited 使用光纤网络系统链接我们全球的交易服务器，这保证了每一项交易的闪电急速执行，提升了78％执行速度，能够链接多个27家不同的流行性服务商，每分钟超过10,000个价格报价更新。 ',
    over:'超过',
    stability:'稳定率',
    promote:'提升',
    execution:'执行速度',
    up:'多达',
    fluidity:'流动性服务商',
    minute:'每分钟超过',
    update:'报价更新',
  },
  //联系我们
  contact:{
    us:'联系我们',
    email:'邮箱',
    address:'地址',
	tel: '电话'
  },
  //产品交易
  product:{
    trade:'交易产品',
    what:'什么是金融软件',
    jiamim:'金融软件提供所有交易加密货币的特性，而不是直接拥有加密货币本身。金融软件是加密货币变化 的转化反映，通过保证金形式交易，赚取买入和卖出的价差。以及，CFD的交易者拥有更方便的融资和做空条件',
    investor:'在 GCT Co., Limited，投资者可以用美元交易多种加密货币差价合约，包括比特币（BITCOIN），比特币现金（BITCION CASH）， 以太币（ETHEREUM），莱特币（LITECOIN）和瑞波币（RIPPLE）等。',
    reality:'现实金融因素',
    unstable:'现实金融世界的不稳定性，让加密货币的需求时而上升',
    attitude:'各国政府态度',
    positive:'政府态度积极，政策环境宽松，加密货币价格通常会上扬',
    influence:'影响加密货币',
    factor:'CFD的因素',
    out:'外部环境影响',
    all:'国家整体经济情况，地缘政治局势也会对加密货币价格造成影响',
    industry:'行业景气程度',
    trend:'加密货币行业的景气趋势也会对行加密货币价格产生影响',
    advantage:'金融软件优势',
    two:'双向交易，涨跌都可盈利',
    hdging:'可对冲风险',
    low:'低门槛，高收益',
    small:'保证金交易，以小博大',
    super:'超低交易成本',
    no:'无涨跌停限制',
    hot:'热门交易品种',
    service:'GCT Co., Limited 始终致力于为客户提供创新的交易服务，推出多种主流金融软件交易品种',
    sell:'卖出',
    buy:'买入',
    highest:'最高',
    lowest:'最低',
    click:'点击查看合约细则',
  },
  //监管机构
  regulatory:{
    authority:'监管机构',
    usa:'美国MSB牌照',
    business:'MSB全称MONEY SERVICES BUSINESS，美国MSB许可证是由FINCEN（美国财政部下设机构金融犯罪执法局监管并提交的一类金融许可证。GCT Co., Limited 在美国获得MSB执照认证，认证时间为2017年2月21日。持有MSB者在美合规制定货币交易/转移（包括数字货币/虚拟货币）业务，且必须接受FINCEN随后的合规法规及审查，要求严格。',
    money:'客户资金安全性',
    account:'GCT Co., Limited 接纳的所有客户资金将存放于独立的银行帐户，与我们的营运资金完全分开存放。若在发生违约事件时，该资金可以直接退还给客户而不会被视为一般债权人的可收回资产。所有的客户的资金将存放于由监管机构的最高等级银行的帐户内。',
    out:'出入金便捷性',
    know:'我们深知出入金对客户的利益，并始终致力于为您提供安全迅速，高质的存取款体验，同时确保各个渠道都是稳定和便捷，让交易者能第一时间安排自己的资产。',
    fair:'公平对待客户原则',
    devoted:'GCT Co., Limited 致力:',
    definition:'提供高清晰度，高效和专业的服务，并定期进行替换以改善及提升服务质量，以满足客户的需求。',
    get:'确保客户获得公平对待，并会迅速和公平地处理任何投诉个案，恪守合规化相关规则。',
    support:'确保公平对待客户的评估获公司全体员工支持。',
    ask:'确保为客户提供的服务达标或超越监管合规化要求。',
  },
  //交易工具
  tools:{
    trade:'交易工具',
    mt4:'GCT Co., Limited 交易平台',
    operation:'操作便捷',
    update:'实时更新',
    safe:'安全保障',
    globe:'GCT Co., Limited 交易平台是全球最受环球和普遍 被金融机构和银行使用的金融交易软件，除了实 时行情图标和交易功能外，还包括18种图标画线 工具，29种国际流行技术指标，自定义指标以及 全面稳健的交易系统，助您实现交易目标。',

    good:'GCT Co., Limited 受投资者广泛好评',
    feature:'具有完备的风险控制功能',
    set:'平台提供多种挂单设置，全面进行 仓位风险管理，100%止损',
    strong:'拥有强大的图表绘制分析功能',
    chart:'平台为广大投资者提供了18种图表 画线工具、29种技术指标、多种看图模板并 支持图表无限量开启，协助投资者做出更 准确的预测和判断。',
    power:'执行力强大，迅捷高效',
    executive:'交易平台执行力强，100% 挂单自动执 行，并以迅捷高效的执行力而全球闻名。目前 超过 100 家金融机构在使用交易平台。',
    lang:'支持多种语言平台界面',
    simplify:'交易平台，提供中文简体、繁体和英文 等31种语言，为全球华人提供优质投资服务。',
    have:'拥有多重安全保障',
    client:'交易平台客户端和服务数据均通过国际认 可保安技术，为投资者提供全方位资金保障。',
    free:'免费模拟交易',
    like:'交易平台，为广大投资爱好者提供免费的 模拟交易平台，深受投资者喜爱。',
    unparalleled:'无与伦比的技术工具箱',
    market:'市场报价-使用它来查看您的交易合约， 并根据自己的需要来显示。',
    visit:'访问您所有的帐户并通过预先植入您 的图表和指标，专家顾问和脚本。',
    check:'终端–查看您的账户结余，敞口头寸， 下单及交易记录等。',
    how:'如何开始？',
    mt4desk:'桌面版',
    cooperation:'交易工具，在与GCT Co., Limited 的紧密合作中，为成功投资者 带来一套便捷且功能齐全的交易解决方案，是现代交易 者的最佳选择。的各类电脑设备。',
    window:'(window) 立即下载',
    mobile:'移动版',
    close:'拥有最佳的移动端交易体验，在与GCT Co., Limited的紧密 合作中，为成功投资者带来一套便捷且功能齐全的交易 解决方案，是现代交易者的最佳选择。',
    ios:'IOS立即下载 ',
    android:'Android立即下载 ',
  },
  //简介
  troduction:{
    btx:'GCT Co., Limited 简介',
    brand:'GCT Co., Limited是全球领先的线上金融软件交易服务供应商品牌， 在美国，英国，塞舌尔，中国香港，马来西亚分别设有办事处和服务中心， 为全球交易者提供主流金融软件金融衍生品交易服务。',
    pioneer:'作为全球市场的先驱者，GCT Co., Limited致力于连接个人交易者与全 球金融市场，在交易平台服务领域已深耕5年，为全球交易者 提供有专业透明的交易环境与卓越的交易体验。',
    spread:'自成立以来，GCT Co., Limited 通过全球通用的平台，为来自全球 30+个国家的100多万投资者提供了金融软件等热门产品 的交易服务，并以大量行业竞争力的点差，几级的订单执行速 度，安全稳定的资金管理服务赢得了全球客户的信赖。',
    team:'我们的团队',
    financial:'GCT Co., Limited拥有一支专业的金融分析师和客服团队，团队核心成员多个 多年国际知名银行，顶尖金融机构，世界500强跨国集团战略决策与 团队管理从业经验。',
    service:'我们的服务',
    help:'除了卓越的交易平台外，GCT Co., Limited 还向客户提供24小时的专业客服， 帮助您更加便利的做出交易决策，并以最实时的信息执行交易。',
    vision:'我们的愿景',
    uphold:'秉承安全规范，诚信可靠的创立初衷；不断创新，持续发展，致力于为客户提供安全的操作系统，完备的交易工具， 丰富的金融资源以及专业的全球支持，打造最佳的交易体验。',

  },
	statement: {
  	title: '隐私条款',
		content: [
			`本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。 您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。`,
			` 1. 适用范围`,
			` (a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；`,
			` (b) 在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；`,
			` (c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。`,
			` 您了解并同意，以下信息不适用本隐私权政策：`,
			` (a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；`,
			` (b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；`,
			` (c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。`,
			` 2. 信息使用`,
			` (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。`,
			` (b) 本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。`,
			` (c) 为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。`,
			` 3. 信息披露`,
			` 在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：`,
			` (a) 经您事先同意，向第三方披露；`,
			` (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；`,
			` (c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；`,
			` (d) 如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；`,
			` (e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；`,
			` (f) 在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。`,
			` (g) 其它本应用根据法律、法规或者网站政策认为合适的披露。`,
			` 4. 信息存储和交换`,
			` 本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。`,
			` 5. Cookie的使用`,
			` (a) 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。`,
			` (b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。`,
			` (c) 通过本应用所设cookies所取得的有关信息，将适用本政策。`,
			` 6. 信息安全`,
			` (a) 本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。`,
			` 7.本隐私政策的更改`,
			` (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。`,
			` (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。`,
			` 请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。`,
		]
	}

}
