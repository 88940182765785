<template>
    <div class="regulatory">
         <!-- banner -->
        <div class="backImg">
            <div class="bannerTit">{{$t('regulatory.authority')}}
              <p>REGULATORY AUTHORITY</p>
            </div>
        </div>

        <!-- 牌照 -->
        <div class="msb">
            <div class="warp">
                <div class="distance">
                    <p class="headTitle">{{$t('regulatory.usa')}}</p>
                    <p class="paragraph">{{$t('regulatory.business')}}</p>
                    <img src="/img/paizhao.jpg">
                </div>
            </div>
        </div>
        <!--  -->
        <div class="safety">
            <div class="warp">
                <div class="distance">
                    <el-row :gutter="40">
                        <el-col :xs="24 " :md="12">
                            <div class="whiteSqu">
                                 <div class="round">
                                    <img src="/img/yuan.png">
                                </div>
                                <p>{{$t('regulatory.money')}}</p>
                                <div class="rectangle"></div>
                                <span>
                                    {{$t('regulatory.account')}}
                                </span>
                            </div>
                        </el-col>
                         <el-col :xs="24" :md="12">
                            <div class="whiteSqu">
                                <div class="round">
                                    <img src="/img/yuan.png">
                                </div>
                                <p>{{$t('regulatory.out')}}</p>
                                <div class="rectangle"></div>
                                
                                
                                <span>
                                    {{$t('regulatory.know')}}
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                         <el-col :span="24">
                            <div class="whiteSqu">
                                <div class="round">
                                    <img src="/img/yuan.png">
                                </div>
                                <p>{{$t('regulatory.fair')}}</p>
                                <div class="rectangle"></div>
                                
                                
                                <span>
                                    {{$t('regulatory.devoted')}}<br>
                                    <ul>
                                        <li>{{$t('regulatory.definition')}}</li>
                                        <li>{{$t('regulatory.get')}}</li>
                                        <li>{{$t('regulatory.support')}}</li>
                                        <li>{{$t('regulatory.ask')}}</li>
                                    </ul>
                                   
                                </span>
                            </div>
                        </el-col>
                    </el-row>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss">
.regulatory{
    .msb{
        .paragraph{
            width: 80%;
        }
        img {
            width: 100%;
        }

    }
    .safety{
        background-image: url(/img/churujin.jpg);
        .whiteSqu{
            background: #fff;
            padding:20px;
            text-align: left;
            margin-top:20px;
            .rectangle{
                width: 20px;
                height: 4px;
                background-color: #87a3e0;
                margin:20px 0;
            }
            >p{
                font-size: 20px;
                font-family: "pingfangBold";
                color:#000;
            }
            img {
                width: 100%;
                max-width: 1200px;
            }
            .round{
                float:right;
            }
            >span{
                font-size:14px;
                color: #79849e;
                line-height: 24px;
                text-align: justify;
                ul li{
                    list-style-type: disc;
                    margin-left: 20px;
                }

            }

        }

    }

}

</style>