import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../components/index.vue'
import troduction from '../components/troduction.vue'
import tools from '../components/tools.vue'
import choose from '../components/choose.vue'
import product from '../components/product.vue'
import regulatory from '../components/regulatory.vue'
import details from '../components/details.vue'
import contactUs from '../components/contactUs.vue'
import statement from '../components/statement.vue'

// Vue.use(VueRouter)
if (process.env.NODE_ENV === 'development') {
  Vue.use(VueRouter)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },{
  // 简介
  path:'/troduction',
  name:'troduction',
  component: troduction
},  {
  //  简介 监管机构
  path:'/regulatory',
  name:'regulatory',
  component: regulatory
},

{
  // 交易工具
  path:'/tools',
  name:'tools',
  component: tools
}, {
  // 交易产品
  path:'/product',
  name:'product',
  component: product
},

{
  // 选择GCT Co., Limited
  path:'/choose',
  name:'choose',
  component: choose
}, {
  // 合约细则
  path:'/details',
  name:'details',
  component: details
}, {
  // 联系我们
  path:'/contactUs',
  name:'contactUs',
  component: contactUs
}, {
  // 隐私
  path:'/statement',
  name:'statement',
  component: statement
},
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
